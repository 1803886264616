
<template>
  <v-row justify="center" :class="$vuetify.breakpoint.mdAndDown? 'ma-':'ma-16'" no-gutters>
    <v-expansion-panels v-model="panel" @change="change">
      <v-expansion-panel
        v-for="(item, i) in items"
        :key="i"
        expand
        v-model="panel"
        :id="item.title.replace('/', '___')"
      >
        <v-expansion-panel-header
          class="py-8 black--text font-weight-bold"
          style="font-size: 16px"
          >{{ item.title }}</v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <template v-for="(subitem, subitemIndex) in filteredFirms(item)">
            <v-row no-gutters :key="subitemIndex" class="py-10" :class="$vuetify.breakpoint.mdAndDown? 'px-1':'px-6'">
              <v-col cols="12" s="12" xs="4" lg="4" xl="4" md="4">
                <v-img
                  width="80%"
                  :src="require('@/assets/imgs/products/' + subitem.imgPath)"
                  contain
                  :alt="subitem.imgPath"
                  class="mt-n7"
                />
              </v-col>
              <v-col cols="12" s="12" xs="8" lg="8" xl="8" md="8">
                <p class="desc mb-5">{{ subitem.text }}</p>
                <p class="desc" v-if="subitem.isPreferred">
                  <v-icon class="mr-2">mdi-star </v-icon> 3545 Preferred Partner
                </p>
                <p class="desc" v-if="subitem.allSizes">
                  <v-icon class="mr-2">mdi-account-group </v-icon> For firms of
                  all sizes
                </p>
                <p class="desc" v-if="subitem.small">
                  <v-icon class="mr-2">mdi-account-group </v-icon> For
                  small/solo firms
                </p>
                <p class="desc" v-if="subitem.medium">
                  <v-icon class="mr-2">mdi-account-group </v-icon>
                  For small to mid-sized firms
                </p>
              </v-col>
            </v-row>
            <v-divider
              :key="subitemIndex + 'pd'"
              v-if="subitemIndex < filteredFirms(item).length - 1"
            ></v-divider>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>


   


<script>
export default {
  watch: {
    "$route.params.id": {
      handler: function (id) {
        this.scrollandOpenPanelByID(this.$route.params.id);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(function () {
      //console.log(this.$route.params.id);
      this.scrollandOpenPanelByID(this.$route.params.id);
    });
  },
  methods: {
    scrollandOpenPanelByID(id) {
      document
        .getElementById(id)
        ?.scrollIntoView({ behavior: "smooth", alignToTop: true });
      this.panel = this.items.findIndex(
        (el) => el.title.replace("/", "___") == id
      );
    },
    change() {
      console.log(this.panel);
    },
    filteredFirms(item) {
      return this.firms.filter((el) => {
        return el.tags.includes(item.title);
      });
    },
  },

  data() {
    return {
      panel: -1,
      firms: [
        {
          tags: [
            "Practice Management",
            "Time Billing/Accounting",
            "Document Management",
          ],
          imgPath: "software_Caret-Legal.jpg",
          text: `Caret Legal is a cloud-based end to end practice management solution for law firms of all sizes. This platform gives legal professionals the power to manage every action taken in every case, and allows for organization, streamlining workflow and facilitating collaboration. Caret Legal’s complete easy to use platform offers robust time, billing and complete accounting; advanced reporting; powerful document management; CRM (Customer Relationship Management) to track and manage prospects; smart email management and cutting edge IOS and Android apps allowing users to enter time and access documents from anywhere.`,
          isPreferred: true,
          allSizes: false,
        },
        {
          tags: ["Document Assembly"],
          imgPath: "software_hotdocs.jpg",
          text: `HotDocs legal document automation, generation and assembly software dramatically reduces the time it takes to create legal documents, by allowing you to transform your frequently used documents and forms into intelligent templates and improves the quality by reducing the likelihood of human error.`,
          isPreferred: true,
          allSizes: false,
        },
        {
          tags: ["Document Assembly", "Automation"],
          imgPath: "software_zencase.jpg",
          text: `ZenCase is a cloud-based practice management platform for lawyers. Simple to use, it allows law firms to streamline time and billing tracking, task management, reporting and document automation workflows in centralized platform. They also offer a cutting-edge CRM that can run a conflict search just a click. Project Management features let you import files from the blueprint library, generate invoice and track time and expenses across all projects. With a strong knowledgebase allows for users to utilize the search functionality and retrieve specific details from the repository. ZenCase integrates with NetDocuments for DMS and QBO for accounting.`,
          isPreferred: true,
          medium: false,
        },
        {
          tags: ["Time Billing/Accounting"],
          imgPath: "software_soluno.jpg",
          text: `Soluno is modern, user-friendly, cloud-based accounting, billing, and business management solution for small to mid-size law firms.
				This easy to use product allows law firms to confidently manage the business of their practice all in one place with cloud-based matter management, time/expense entry, billing, accounting, and reporting. Soluno helps firms streamline their cash flow from data entry to invoicing, so they can get back to what matters most; practicing law.`,
          isPreferred: true,
          medium: false,
        },
        {
          tags: ["Document Management"],
          imgPath: "netdocs-badges.jpg",
          text: `NetDocuments is the leading cloud-based document and email management system providing enterprise security, mobility, and collaboration solutions for law firms of all sizes. Their applications make it easy to create, store, locate, collaborate and edit documents from anywhere while using any device. Find out how more about how you can Organize, Protect, Plan, Deliver and Learn.`,
          isPreferred: true,
          medium: false,
        },
        {
          tags: ["Document Assembly"],
          imgPath: "software_word.jpg",
          text: `One of the most used applications for legal in the Microsoft Office Suite, Word is used to create, share, and edit documents.`,
          allSizes: false,
        },
        {
          tags: ["Document Management"],
          imgPath: "software_symphony.jpg",
          text: `Symphony Suite runs in the background to automatically OCRs every file within your Worldox repository. This means every PDF is text-searchable, including those that are scanned, emailed, downloaded from the Internet, and even the PDFs you already have on file.`,
          allSizes: false,
        },
        {
          tags: ["Document Management"],
          imgPath: "software_worldox.jpg",
          text: `Worldox is an easy to use document and email management system offering on-site, enterprise, and cloud-based management solutions for law firms. Worldox allows you to enhance your ability to organize, control and access your data.`,
          allSizes: false,
        },
        
        {
          tags: ["Practice Management", "Time Billing/Accounting"],
          imgPath: "software_rocket-matter.jpg",
          text: `Rocket Matter is a cloud-based legal billing and management platform designed to cater to small and midsize law practices. It features time and billing management, calendar, document management, payments and collaboration management modules. The ‘Bill As You Work’ technology helps allows practices to record billable and non-billable time, create bills, send invoices to clients and more. Allows for custom field control, and has very powerful reporting and BI features.`,
          medium: false,
        },
        {
          tags: ["Practice Management"],
          imgPath: "software_time-matters.jpg",
          text: `Time Matters is a premise-based practice management software offering a firm-wide calendar, document management, conflict-of-interest searches, and email management. The software is highly customizable and flexible and can be integrated with NetDocuments and HotDocs and XpressDox.`,
          medium: false,
        },
        {
          tags: ["Practice Management"],
          imgPath: "software_practicemaster.jpg",
          text: `PracticeMaster is a comprehensive, premise-based, and flexible practice management solution that allows firms to manage all matter information including contacts, calendaring, tasks, emails, and documents. It is customizable with the ability to create workflows to help in task automation. It integrates tightly with Tabs3 billing and accounting to meet the firm’s complete requirements. PracticeMaster also integrates with NetDocuments and WorldDox and HotDocs to further extend its capabilities. It includes cloud-enablement for access to information remotely.`,
          medium: false,
        },
        {
          tags: ["Time Billing/Accounting"],
          imgPath: "software_pclaw.jpg",
          text: `PCLaw® is a premise-based software that allows law firms to manage matters; track time, expenses, calendar appointments and tasks; bill and collect payments from clients; pay vendors; reconcile bank statements and manage trust accounts from a single source. The accounting capabilities and built-in reports (especially Client Disbursement and Analysis reports) make this legacy software appealing and able to compete with other cloud based applications.`,
          medium: false,
        },
        {
          tags: ["Time Billing/Accounting"],
          imgPath: "software_tabs3.jpg",
          text: `Tabs3 is an on-premise software offering practice management, case management and financials for small to mid-size firms. Easily enter and track time/expenses with timers which help users track time and turn billable hours into fees that appear on their client’s next statement. Advanced billing rate options, invoicing options, and credit card processing are available as well as built-in detailed reports.`,
          medium: false,
        },
        {
          tags: ["Time Billing/Accounting"],
          imgPath: "software_sage.jpg",
          text: `Sage Timeslips is an on-premise software that makes it easy to capture time and expense tracking allowing law firms to increase productivity and deliver more accurate and timely billing to their clients. Quickly customize professional looking bills with flexible billing arrangements and choose from over 100 predefined reports or customize your own with ease. Integrates well with QuickBooks desktop.`,
          medium: false,
        },
        {
          tags: ["Time Billing/Accounting"],
          imgPath: "software_quickbooks.jpg",
          text: `QuickBooks is an accounting software geared mainly toward small and medium-sized businesses. They offer on-premise as well as cloud-based options. While QuickBooks is not designed for law firms and therefore has several limitations in the area of time/billing and reporting, it does integrate with many legal specific products (both on premise and cloud-based), that manage those aspects.`,
          medium: false,
        },
        {
          tags: ["Time Billing/Accounting"],
          imgPath: "software_timesolv.jpg",
          text: `TimeSolv is a cloud-based legal time tracking and billing platform catering to law firms, accountants, consultants, architects, and freelancers, helping them manage daily business operations. Features include time tracking, assigning tasks and billing functionality, allowing users to track billable hours and budgeting in real time. Links to QBO and Xero for accounting. Other integrations include Office 365, NetDocuments, LawPay and LexCharge. Offers client portal, online billing, and multi-currency options.`,
          medium: false,
        },
        {
          tags: ["Document Assembly"],
          imgPath: "softwar_actionstep.jpg",
          text: `Actionstep is a powerful cloud-based legal practice & case management software with everything you need to run your entire law firm. Their Document Automation function allows you to create document templates that can be automatically populated with client and matter data, allowing you to save time and reduce errors.`,
          medium: false,
        },
        {
          tags: ["Document Management"],
          imgPath: "software_box.jpg",
          text: `Box is a cloud-based document storage service that allows users to collaborate both within and outside their organization by sharing individual documents and folders. Box integrates with a number of cloud-based practice management services including: Clio, ActionStep, CosmoLex and Caret Legal such that matter-specific folders are automatically created.`,
          medium: false,
        },
        {
          tags: [
            "Practice Management",
            "Time Billing/Accounting",
            "Automation",
          ],
          imgPath: "software_clio.jpg",
          text: `Clio is a cloud-based legal practice management solution suitable for solo practictioners to large law firms. The platform enables law firms and other businesses in the legal industry to track important deadlines, manage client cases and documents, bill clients, and accept payments. Clients can easily communicate and collaborate with their lawyer, and Clio integrates with over 50 outside applications.`,
          small: false,
        },
        {
          tags: [
            "Practice Management",
            "Time Billing/Accounting",
            "Document Assembly",
          ],
          imgPath: "software_cosmolex.jpg",
          text: `CosmLlex is a cloud-based law practice management software product that integrates trust & business accounting, time tracking, billing, email, and document management, as well as tasks and calendaring in a single application. This all-in-one product is the small firm’s answer to reducing overhead and capitalizing on a cloud-based system with a one-click 3-way reconciliation that helps ensure your firm is audit ready at any time.`,
          small: false,
        },
        {
          tags: ["Time Billing/Accounting"],
          imgPath: "software_billing.jpg",
          text: `Billing Matters is a timekeeping and billing system that may be added to Time Matters as an option. This legacy software is designed to manage the full range of billing processes, from the simplest to the most advanced requirements. It allows the user to control how they enter time and expenses, bill clients and customers, track and report Matter/Case information, manage workflow and tasks, and control the schedules for the entire office. Billing Matters integrates with Time Matters, and QuickBooks for accounting, is highly customizable, and a flexible billing system.`,
          small: false,
        },
        {
          tags: ["Practice Management", "Time Billing/Accounting"],
          imgPath: "software_centerbase.jpg",
          text: `Centerbase is a flexible cloud-based practice management application solution that seamlessly integrates and simplifies many firm processes such as client development, intake, timekeeping, billing, and document management.`,
          allSizes: false,
        },
        {
          tags: ["CRM", "Document Assembly", "Automation"],
          imgPath: "software-_0003_office.jpg",
          text: `Office 365 is now Microsoft 365. New name, more benefits. … Microsoft 365 plans for home and personalinclude the robust Office desktop apps that you’re familiar with, like Word, PowerPoint, and Excel. You also get extra online storage and cloud-connected features that let you collaborate on files in real time.`,
          allSizes: false,
        },
        {
          tags: ["Document Assembly", "Automation"],
          imgPath: "software_xpressdox.jpg",
          text: `XpressDox is a cloud-based and desktop document automation solution. It handles complex logic, data merges, caclulations, and workflow automation. The system can be connected through ODBC to a range of practice management systems to allow data entered in client intakes to be used in producing final polished legal documents.`,
          allSizes: false,
        },
      ],
      items: [
        {
          title: "Practice Management",
        },
        {
          title: "Time Billing/Accounting",
        },
        {
          title: "Document Assembly",
        },
        {
          title: "Document Management",
        },
        {
          title: "Automation",
        },
        {
          title: "CRM",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.v-divider {
  background-color: $yellow_c !important;
}
.v-icon {
  color: #1f628e !important;
}
.desc {
  font-size: 15px !important;
}
</style>